<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="rows"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #job="{row}">
      <JobLink
        :data="row.job"
        print-description
        :show-preview="false"
      />
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
            minWidth: 60,
            maxWidth: 140,
          },
          {
            label: this.$t('Status'),
            prop: 'job.status',
            align: 'center',
            component: 'Status',
            minWidth: 40,
            maxWidth: 60,
            summary: () => this.$t('Totals:'),
          },
          {
            label: this.$t('Percent <br> Complete'),
            prop: 'totals.all.percent_complete',
            align: 'center',
            component: 'FormattedPercent',
            summary: () => this.$formatPercent(this.grandTotals.percent_complete),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Contract <br> Amount'),
            prop: 'totals.all.contract_amount',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.contract_amount),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Billings <br> To-Date'),
            prop: 'totals.all.billings_to_date',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.billings_to_date),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Cost <br> To-Date'),
            prop: 'totals.all.cost_to_date',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.cost_to_date),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Budgeted <br> Cost'),
            prop: 'totals.all.budgeted_cost',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.budgeted_cost),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Gross Profit <br> Budgeted'),
            prop: 'totals.all.budgeted_gross_profit',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.budgeted_gross_profit),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Gross Profit <br> Projected'),
            prop: 'totals.all.est_gross_profit',
            align: 'right',
            component: 'FormattedPrice',
            summary: () => this.$formatPrice(this.grandTotals.est_gross_profit),
            minWidth: 80,
            maxWidth: 150,
          },
        ]
      },
      grandTotals() {
        return this.data.grand_totals?.all || {}
      },
    },
    methods: {
      composeRows(data) {
        this.rows = data
      },
    },
  }
</script>

<template>
  <div
    :class="{'pt-4': readOnly}"
    class="px-4 pb-4 timecard-entries"
  >
    <BaseAlert
      v-if="calculateUsingSalaryLabel"
      :type="$promptType.Info"
      class="mt-4"
    >
      {{ calculateUsingSalaryLabel }}
    </BaseAlert>
    <AgDataTable
        :actions="readOnly ? '' : 'add'"
        domLayout="autoHeight"
        :read-only="readOnly"
        :pagination="false"
        :default-filters="false"
        :compact="true"
        :data="data"
        :columns="columns"
        :add-text="$t('Add Entry')"
        :enableRangeSelection="true"
        :addRowOnTab="true"
        :get-empty-row="getEmptyRow"
        :authorize-to-copy-last-row="!readOnly"
        :get-row-id="getRowId"
        :showCellsLegend="!readOnly"
        v-bind="extraGroupParams"
        suppressColumnReordering
        @add="onAddRow"
        @cell-focused="onCellFocused"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="onGridReady"
    >
      <template #additional-actions-before>
        <BaseAlert
          v-if="payrollHasAdjustments && !readOnly"
          :type="$promptType.Danger"
        >
          {{ $t('Payroll for this timecard has adjustments.') }}
          <span class="font-semibold">{{ $t('Editing entries will lose these adjustments.') }}</span>
        </BaseAlert>
      </template>
      <template #additional-actions>
        <TableSyncButton
          v-if="!readOnly"
          :loading="updatingEntries"
          class="mr-2"
          @click="syncTimeCard"
        />
      </template>
      <template #date="{row}">
        <TimecardEntryDate :row="row" :days="params.days"/>
      </template>
      <template #overrides="{row}">
        <div class="flex justify-center w-full">
          <TableEditButton :skipFocus="true"/>
        </div>
      </template>
      <template #view_actions="{row}">
        <div v-if="row?.id" class="flex justify-center w-full">
          <TableViewButton @click="viewSummary(row)" :skipFocus="true"/>
        </div>
      </template>
      <template #row_actions="{row}">
        <div
          v-if="!row?.timesheet_entry_id"
          class="flex justify-center w-full">
          <TableDeleteButton :skipFocus="true"/>
        </div>
      </template>
    </AgDataTable>
    <div class="w-full mt-4 grid grid-cols-2">
      <div>
        <div tabindex="0">
          <!--Temporary hack to avoid focusing on check type after tabbing on last grid row to add an entry-->
        </div>
        <TimecardEntriesForm
            v-if="!readOnly"
            :time-card="params.data"
            @save="params.onUpdateTimeCard($event, params)"/>
      </div>
      <OverrideSummary
          :fixed="readOnly"
          :data="lastSelectedRow"
          :employee="params.data.employee"
          :days="params.days"
          @close="lastSelectedRow = null"
      />
      <TimecardShortSummary
        v-if="!readOnly && lastSelectedShortRow"
        :data="params.data"
        :days="params.days"
        :employee="params.data.employee"
        @close="lastSelectedShortRow = null"
      />
    </div>
  </div>
</template>
<script>
import { cloneDeep, startCase } from 'lodash'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { equipmentCostAddlSources, setTypeSources } from "@/components/grid-table/utils/cost-center";
import {
  getCostCenterRowDefaults,
  getEmptyEntry,
  getTotals,
  isRowValid,
  RateFrom,
  // Infos and warnings
  calculateUsingSalary,
  isSalaryEntry,
  salaryWillBeReduced,
  nonSalaryCodesWillNotBeDistributed,
  couldLeadToUnderOrOverPayment,
  hasSalaryEntries,
  hasBothSalaryAndDistributions,
  isSalaryEmployeeWithHourlyRates,
} from "@/modules/payroll/utils/timeCardUtils";
import TimeCardEntryOverridesDialog from "@/modules/payroll/components/timecard/TimeCardEntryOverridesDialog";
import TimeCardEntryDeleteDialog from "@/modules/payroll/components/timecard/TimeCardEntryDeleteDialog";
import { cellClasses, requiredValueSetter, stopEditingOnTab } from "@/components/ag-grid/columnUtils";
import { dateTypes } from "@/plugins/dateFormatPlugin";
import OverrideSummary from "@/modules/payroll/components/timecard/OverrideSummary";
import { costCenterFields, hourFields } from "@/modules/common/util/costCenterUtils";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import axios from "axios";
import { computeSpecialPayUnits, getSpecialCodeTypeLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import TimecardEntriesForm from "@/modules/payroll/components/timecard/TimecardEntriesForm";
import {
  accountCol,
  additionalSourceCol,
  costCenterCol,
  sourceCol, specialUnitsCol,
  typeCol,
  updateCostCenterHeaderNames,
} from "@/components/ag-grid/columns/costCenterColumns";
import { getSpecialSourceOption } from "@/components/grid-table/utils/cost-center-cell";
import { CornerUpLeftIcon, ClockIcon } from 'vue-feather-icons'
import { isAuthorized } from "@/plugins/actionAuthorization";
import TimecardEntryGroupRow from "@/modules/payroll/components/timecard/TimecardEntryGroupRow.vue";
import bus from '@/event-bus/EventBus';
import TableSyncButton from "@/components/table/actions/TableSyncButton.vue";
import TimecardShortSummary from "@/modules/payroll/components/timecard/TimecardShortSummary.vue";
import TimecardEntryDate from "@/modules/payroll/components/timecard/TimecardEntryDate.vue";
import { saveInlineEntry, updateInlineEntry } from "@/components/ag-grid/tableUtils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { delay } from "@/utils/utils";
import { equipmentCostTypeFor } from '@/enum/equipment'
import { laborHourTypeOptions } from "@/modules/service-billing/util/service-billing";
import { employeeStatuses } from '@/enum/enums'

export default {
    components: {
      TimecardEntryDate,
      TimecardShortSummary,
      TableSyncButton,
      OverrideSummary,
      TimeCardEntryDeleteDialog,
      TimeCardEntryOverridesDialog,
      TimecardEntriesForm,
      CornerUpLeftIcon,
      ClockIcon,
      TimecardEntryGroupRow,
    },
    data() {
      return {
        data: this.params?.data?.entries || [],
        grid: null,
        lastSelectedRow: null,
        lastSelectedShortRow: null,
        updatingEntries: false,
      }
    },
    computed: {
      timeCard() {
        return this.params?.data || {}
      },
      payCodes() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.PayCodes)
      },
      payrollHasAdjustments() {
        return this.timeCard?.payroll_has_adjustments
      },
      readOnly() {
        return this.params.readOnly
      },
      timeCardBatch() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      canUpdateBatch() {
        return isAuthorized('authorizedToUpdate', this.timeCardBatch)
      },
      groupRowRendererParams() {
        return {

        }
      },
      extraGroupParams() {
        if (!this.readOnly) {
          return {}
        }
        return {
          groupRowRendererParams: {
            innerRenderer: 'TimecardEntryGroupRow',
            suppressCount: true,
            groupRowRendererParams: {
              days: this.params.days,
            },
          },
          groupDefaultExpanded: -1,
          groupIncludeFooter: true,
          groupDisplayType: 'groupRows'
        }
      },
      equipmentCostTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes) || []
      },
      employee() {
        return this.timeCard.employee
      },
      employeeSalary() {
        return Number(this.employee.salary_amount || 0)
      },
      employeeStatus() {
        return this.employee.status
      },
      isSalaryEmployee() {
        return this.employee.status === employeeStatuses.SALARY
      },
      calculateUsingSalaryLabel() {
        if (!calculateUsingSalary(this.timeCard)) {
          return ''
        }

        const calculateHoursUsingSalary = this.$settings(this.$modules.PR, 'calculate_hours_using_salary')
        const calculateRateUsingSalary = this.$settings(this.$modules.PR, 'calculate_rate_using_salary')

        if (calculateHoursUsingSalary && calculateRateUsingSalary) {
          return this.$t('Rates and hours will be calculated based on Salary for this Employee.')
        }

        if (calculateHoursUsingSalary) {
          return this.$t('Hours will be calculated based on Salary for Salary for this Employee.')
        }

        return this.$t('Rates will be calculated based on Salary for this Employee.')
      },

      columns() {
        return [
          {
            headerName: this.$t('Day'),
            field: 'date',
            minWidth: this.readOnly ? 150 : 120,
            maxWidth: this.readOnly ? 120 : 180,
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.params.days,
            },
            required: true,
            editable: this.canEditEntry,
            cellClass: this.editCellClass,
            valueSetter: requiredValueSetter,
            rowGroup: this.readOnly,
            hide: this.readOnly,
          },
          {
            ...costCenterCol,
            valueSetter: params => {
              params.data.cost_center = params.newValue
              params.data = getCostCenterRowDefaults(params.data, this.params.data.employee)
              params.node.setData(params.data)
              return true
            },
            editable: this.canEditEntry,
            cellClass: this.editCellClass,
          },
          {
            ...sourceCol,
            editable: params => {
              return sourceCol.editable(params) && this.canEditEntry(params)
            },
            cellClass: params => {
              if (!this.canEditEntry(params)) {
                return cellClasses.ReadOnly
              }
              return sourceCol.cellClass(params)
            },
          },
          {
            ...typeCol,
            editable: params => {
              return typeCol.editable(params) && this.canEditEntry(params)
            },
            cellClass: params => {
              if (!this.canEditEntry(params)) {
                return cellClasses.ReadOnly
              }
              return typeCol.cellClass(params)
            },
            cellEditorParams: params => {
              const { cost_center } = params.data
              const resourceMapping = {
                [costCenterTypes.Job]: this.$globalResources.JobCostTypes,
                [costCenterTypes.WorkOrder]: this.$globalResources.ServiceBillingCostTypes,
                [costCenterTypes.Equipment]: this.$globalResources.EquipmentTypes,
              }
              const resourceName = resourceMapping[cost_center]

              let options = []
              if (cost_center === costCenterTypes.Labor) {
                options = laborHourTypeOptions
              }

              let filterMethod = null
              if (cost_center === costCenterTypes.Equipment) {
                filterMethod = (option) => {
                  return ![
                    equipmentCostTypeFor.Aquisition,
                    equipmentCostTypeFor.Depreciation,
                  ].includes(option.for)
                }
              }
          
              return {
                resourceName,
                options,
                filterMethod,
              }
            },
          },
          {
            ...additionalSourceCol(),
            cellEditorParams: params => {
              const { cost_center, type_id } = params.data

              if (cost_center === costCenterTypes.Equipment) {
                return {
                  active: true,
                  available_in_timesheets: true,
                  canInjectCraftCode: true,
                  type_id,
                  onChange: this.saveOrUpdateEntry,
                }
              }

              return {}
            },
            editable: params => {
              return this.canEditAddlSource(params)
            },
            cellClass: params => {
              if (!this.canEditAddlSource(params)) {
                return cellClasses.ReadOnly
              }

              return additionalSourceCol().cellClass(params)
            },
          },
          {
            ...accountCol(this.readOnly),
            hide: true,
          },
          {
            field: 'regular_hours',
            headerName: 'Regular Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            valueSetter: params => requiredValueSetter(params, 0),
            cellClass: params => {
              if (isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'regular')) {
                return cellClasses.Invalid
              }
              return ''
            },
            tooltipValueGetter: params => {
              const salaryWithRate = isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'regular')
              if (salaryWithRate) {
                return salaryWithRate
              }

              return ''
            },
            tooltipComponent: 'AgCustomTooltip',
            aggFunc: 'sum',
          },
          {
            field: 'overtime_hours',
            headerName: 'Overtime Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            valueSetter: params => requiredValueSetter(params, 0),
            cellClass: params => {
              if (isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'overtime')) {
                return cellClasses.Invalid
              }
              return ''
            },
            tooltipValueGetter: params => {
              const salaryWithRate = isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'overtime')
              if (salaryWithRate) {
                return salaryWithRate
              }

              return ''
            },
            tooltipComponent: 'AgCustomTooltip',
            aggFunc: 'sum',
          },
          {
            field: 'premium_hours',
            headerName: 'Premium Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            valueSetter: params => requiredValueSetter(params, 0),
            cellClass: params => {
              if (isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'premium')) {
                return cellClasses.Invalid
              }
              return ''
            },
            tooltipValueGetter: params => {
              const salaryWithRate = isSalaryEmployeeWithHourlyRates(params, this.timeCard, 'premium')
              if (salaryWithRate) {
                return salaryWithRate
              }

              return ''
            },
            tooltipComponent: 'AgCustomTooltip',
            aggFunc: 'sum',
          },
          {
            field: 'special_source_type',
            headerName: 'Special Type',
            minWidth: 60,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.SpecialCodeType,
            valueGetter: params => {
              return getSpecialCodeTypeLabel(params.data?.special_source_type)
            },
            valueSetter: params => {
              params.data.special_source_id = ''
              params.data.units = 0
              params.data.special_source_type = params.newValue
              if (!params.newValue) {
                params.data.special_rate = 0
                params.data.special_account = null
              }
              computeSpecialPayUnits(params)
              params.node.setData(params.data)
              if (params.newValue === codeTypes.TIME_OFF) {
                this.$store.dispatch('globalLists/getTimeOffStatuses', this.params.data?.employee_id)
              }
              return true
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab';
              if (isTabKey && !params.data.special_source_type) {
                params.api.stopEditing();
              }
            }
          },
          {
            field: 'special_source_id',
            headerName: 'Special Code',
            minWidth: 120,
            maxWidth: 180,
            editable: params => {
              return !!params.data.special_source_type
            },
            suppressNavigable: params => {
              return !params.data.special_source_type
            },
            component: 'SpecialSourceLink',
            cellRendererParams: {
              target: '_blank',
            },
            cellEditorSelector: params => {
              if (params.data?.special_source_type === codeTypes.TIME_OFF) {
                return {
                  component: cellEditors.EmployeeTimeOffSelect,
                  params: {
                    employeeId: this.params.data?.employee_id,
                  }
                }
              }

              if (params.data?.special_source_type === codeTypes.PAY && !this.isSalaryEmployee) {
                return {
                  component: cellEditors.SpecialCode,
                  params: {
                    ownFilter: (options) => {
                      return options.filter((option) => {
                        return option?.code !== 'SALARY'
                      })
                    },
                  }
                }

              }
              return {
                component: cellEditors.SpecialCode
              }
            },
            cellClass: params => {
              const { special_source_type, special_source_id } = params.data || {}
              const hasValue = !!special_source_id

              if (!hasValue && special_source_type) {
                return cellClasses.Invalid
              }
              if (!special_source_type) {
                return cellClasses.ReadOnly
              }

              if (!this.isSalaryEmployee && isSalaryEntry(params.data)) {
                return cellClasses.Invalid
              }

              if (calculateUsingSalary(this.timeCard) && isSalaryEntry(params.data) && hasBothSalaryAndDistributions(params)) {
                return cellClasses.Invalid
              }

              if (salaryWillBeReduced(params, this.timeCard)) {
                return cellClasses.Warning
              }

              if (nonSalaryCodesWillNotBeDistributed(params, this.timeCard)) {
                return cellClasses.Warning
              }

              return ''
            },
            tooltipComponent: 'AgCustomTooltip',
            tooltipValueGetter: params => {
              const warnings = []
              if (!this.isSalaryEmployee && isSalaryEntry(params.data)) {
                warnings.push(this.$t(`${startCase(this.employeeStatus)} Employees should not use SALARY pay code.`))
              }

              if (calculateUsingSalary(this.timeCard) && isSalaryEntry(params.data) && hasBothSalaryAndDistributions(params)) {
                warnings.push(this.$t('SALARY and Distribution entries cannot be used together.'))
                warnings.push(this.$t('This item will not be distributed bcause the timecard has non-salary, non-time off entries with rates set.'))
              }

              const salary_willBeReduced = salaryWillBeReduced(params, this.timeCard)
              if (salary_willBeReduced) {
                warnings.push(salary_willBeReduced)
              }

              const nonSalary_willNotBeDistributed = nonSalaryCodesWillNotBeDistributed(params, this.timeCard)
              if (nonSalary_willNotBeDistributed) {
                warnings.push(nonSalary_willNotBeDistributed)
              }

              if (!warnings?.length) {
                return ''
              }

              return warnings
            },
            valueSetter: params => {
              params.data.special_source_id = params.newValue
              const option = getSpecialSourceOption(params.data)

              if (params.data.special_source_type === codeTypes.EQUIPMENT) {
                params.data.special_rate = option?.standard_job_cost_hourly_rate || 0
              }
              if (params.data.special_source_type === codeTypes.MATERIAL) {
                params.data.special_rate = option?.standard_unit_rate || 0
              }
              if (!params.newValue) {
                params.data.special_account = null
              }
              return true
            },
          },
          {
            ...specialUnitsCol,
            cellClass: params => {
              const { special_source_type, special_source_id } = params.data || {}
              if (!special_source_type && !special_source_id) {
                return cellClasses.ReadOnly
              }

              if (hasSalaryEntries(params) && couldLeadToUnderOrOverPayment(params, this.timeCard)) {
                return cellClasses.Warning
              }

              return ''
            },
            tooltipComponent: 'AgCustomTooltip',
            tooltipValueGetter: params => {
              const over_under_payment = hasSalaryEntries(params) && couldLeadToUnderOrOverPayment(params, this.timeCard)
              if (!over_under_payment) {
                return ''
              }
              return over_under_payment
            }
          },
          {
            headerName: ' ',
            field: 'overrides',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            editable: true,
            cellEditor: 'TimeCardEntryOverridesDialog',
            hide: this.readOnly,
            cellEditorParams: {
              employee_id: this.params.data?.employee_id,
              saveOrUpdateEntry: this.saveOrUpdateEntry,
            },
            suppressPaste: true,
            cellEditorPopup: true,
            suppressNavigable: true,
            onCellClicked: params => {
              params.api.startEditingCell({
                rowIndex: params.rowIndex,
                colKey: params.column.colId,
              })
            }
          },
          {
            headerName: ' ',
            field: 'view_actions',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            hide: !this.readOnly,
          },
          {
            headerName: ' ',
            field: 'row_actions',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            cellEditor: 'TimeCardEntryDeleteDialog',
            cellEditorPopup: true,
            suppressPaste: true,
            suppressNavigable: true,
            hide: !this.canUpdateBatch,
            editable: this.canEditEntry,
            cellEditorParams: {
              onConfirm: (params) => {
                this.updateParentRow(params, 'remove')
                if (this.isSalaryEmployee) {
                  this.grid.api?.refreshCells({ force: true })
                }
              }
            },
            onCellClicked: params => {
              if (!this.canEditEntry(params)) {
                return
              }
              params.api.startEditingCell({
                rowIndex: params.rowIndex,
                colKey: params.column.colId,
              })
            }
          },
        ]
      },
    },
    methods: {
      canEditAddlSource(params) {
        if (!this.canEditEntry(params) || !additionalSourceCol().editable(params)) {
          return false
        }

        const { cost_center, type_id } = params.data || {}
        if (cost_center === costCenterTypes.Equipment) {
          const equipmentCostType = this.equipmentCostTypes.find(t => t.id === type_id)
          return equipmentCostType?.for === equipmentCostTypeFor.Repairs
        }

        return true
      },
      editCellClass(params) {
        if (!this.canEditEntry(params)) {
          return cellClasses.ReadOnly
        }
        return ''
      },
      canEditEntry(params) {
        return !params?.data?.timesheet_entry_id
      },
      getRowId(params) {
        return params.data._localId || params.data.id
      },
      initData() {
        this.data = this.params?.data?.entries || []
      },
      onCellFocused(params) {
        updateCostCenterHeaderNames(params)
        const rowData = params.api.getDisplayedRowAtIndex(params.rowIndex)
        const row = rowData?.data
        if (row) {
          this.closeOtherSummaries()
          this.lastSelectedRow = row
          this.lastSelectedShortRow = row
        }
      },
      viewSummary(row) {
        if (!row) {
          return
        }
        this.closeOtherSummaries()
        this.lastSelectedRow = row
        this.lastSelectedShortRow = row
      },
      closeOtherSummaries() {
        bus.$emit('close-timecard-entry-summary')
      },
      handleBusEvents() {
        bus.$on('close-timecard-entry-summary', () => {
          this.lastSelectedRow = null
          this.lastSelectedShortRow = null
        })
      },
      async onCellValueChanged(params) {
        await delay(50)

        const field = params?.colDef?.field

        if (hourFields.includes(field)) {
          this.updateParentRow(params, 'update')
          computeSpecialPayUnits(params)
        }

        if (!costCenterFields.includes(field)) {
          await this.saveOrUpdateEntry(params)
          return
        }

        await this.saveOrUpdateEntry(params)
      },
      onGridReady(params) {
        this.grid = params
      },
      getTableData(params) {
        const api = params?.api || this.grid?.api
        const data = []
        api?.forEachNode(node => {
          data.push(node.data)
        })
        return data
      },
      getTableNodes() {
        const nodes = []
        this.grid.api.forEachNode(node => {
          nodes.push(node)
        })
        return nodes
      },
      async onAddRow(params, createdRow) {
        this.updateParentRow(createdRow, 'add')
        if (createdRow) {
          await this.saveOrUpdateEntry(params)
        }
      },
      getEmptyRow() {
        const entries = this.getTableData()
        let previousRow = entries[entries.length - 1]
        return getEmptyEntry({
          order: entries.length - 1,
          employee: this.params.data.employee,
          days: this.params.days,
          previousRow,
        })
      },
      async syncTimeCard() {
        try {
          this.updatingEntries = true
          const timeCardId = this.params.data?.id
          await axios.post(`/restify/timecards/${timeCardId}/actions?action=sync-timecard`)
          this.params.onRefresh?.()
        } finally {
          this.updatingEntries = false
        }
      },
      mapEntryToRequest(entry) {
        const defaultEntry = getEmptyEntry({
          employee: this.params.data.employee,
          days: this.params.days,
        })
        let mappedEntry = setTypeSources(entry)
        mappedEntry = cloneDeep(entry)

        if (entry.addl_source_type !== equipmentCostAddlSources.RepairOrderEntry) {
          delete mappedEntry.meta.repair_order_id
          delete mappedEntry.meta.roe_description
        }

        for (let key in defaultEntry) {
          mappedEntry[key] = entry[key]
        }
        mappedEntry.timecard_id = entry.timecard_id
        mappedEntry.date = entry.date
        if (entry.id) {
          mappedEntry.id = entry.id
          mappedEntry.date = this.$formatDate(entry.date, dateTypes.IsoDate, true)
        } else {
          mappedEntry.date = this.$formatDate(new Date(entry.date), dateTypes.IsoDate)
        }
        if (!mappedEntry.timecard_id) {
          mappedEntry.timecard_id = this.params.data.id
        }
        if (!mappedEntry.certified_payroll_exempt_from) {
          mappedEntry.certified_payroll_exempt_from = RateFrom.Undefined
        }
        if (!mappedEntry.sub_trade_from) {
          mappedEntry.sub_trade_from = RateFrom.Undefined
        }

        if (!mappedEntry.meta) {
          mappedEntry.meta = {}
        }

        return mappedEntry
      },
      async updateEntry(row, params) {
        const rowData = await updateInlineEntry({ row, params, url: `/restify/timecard-entries/${row.id}` })
        if (this.timeCard.payroll_has_adjustments) {
          this.params.data.payroll_has_adjustments = false
        }
        return rowData
      },
      async saveOrUpdateEntry(params) {
        const row = params.data
        const isValid = isRowValid(params, this.timeCard)
        if (this.isSalaryEmployee) {
          this.grid.api?.refreshCells({ force: true })
        }
        if (!isValid) {
          return params.data
        }
        const entry = this.mapEntryToRequest(row)
        let savedData = params.data
        try {
          params.data.loading = true
          if (!entry.id) {
            savedData = await saveInlineEntry({ row: entry, params, url: '/restify/timecard-entries' })
          } else {
            savedData = await this.updateEntry(entry, params)
          }
          await this.$store.dispatch('payroll/resetTimecardBatchToPending')
          this.lastSelectedRow = savedData
          this.lastSelectedShortRow = savedData
        } finally {
          params.node.setData(params.data)
          if (this.isSalaryEmployee) {
            this.grid.api.refreshCells({ force: true })
          }
        }
        return savedData
      },
      updateParentRow(params, transaction = 'add') {
        const entries = this.getTableData()
        let totals = getTotals(entries)
        let entries_count = this.params.data.entries_count || 0


        if (transaction === 'add') {
          entries_count++
        } else if (transaction === 'remove') {
          entries_count--
        }

        const node = this.params.node
        this.params.data.entries = entries

        node.setDataValue('totals.regular_hours', totals.regular_hours)
        node.setDataValue('totals.premium_hours', totals.premium_hours)
        node.setDataValue('totals.overtime_hours', totals.overtime_hours)
        node.setDataValue('totals.total_hours', totals.total_hours)
        node.setDataValue('entries_count', entries_count)
      },
      async resetFromFields() {
        const confirmed = await this.$confirm({
          title: this.$t('Are you sure?'),
          description: this.$t('This will reset time card entry fields such as rates, accounts, state etc to their default values.'),
          buttonText: this.$t('Reset fields'),
        })
        if (!confirmed) {
          return
        }

        // TODO - reset fields when backend is implemented
        // /restify/timecards/:id/actions?action=reset-overrides - POST
        // Refresh the table afterwards
      },
    },
    created() {
      this.initData()
      this.handleBusEvents()
    }
  }
</script>

export const defaultRoles = () => {
  return [
    {
      "name": "Accounts Payable",
      "description": "Responsible for managing vendor invoices, payments, retentions, and related financial documents.",
      "file": "accounts_payable"
    },
    {
      "name": "Accounts Receivable",
      "description": "Handles customer invoicing, payment tracking, retentions, and customer-related financial records.",
      "file": "accounts_receivable"
    },
    {
      "name": "Equipment Purchasing",
      "description": "Oversees equipment lifecycle, inventory, materials, and purchase orders for operational needs.",
      "file": "equipment_purchasing"
    },
    {
      "name": "Operations",
      "description": "Manages daily business functions across job costing, billing, equipment, and purchasing but no access to Payroll.",
      "file": "operations"
    },
    {
      "name": "Project Manager",
      "description": "Coordinates job execution, tracks costs, manages documents, and oversees billing and equipment.",
      "file": "project_manager"
    },
    {
      "name": "Timesheets",
      "description": "Handles timesheet approvals and task scheduling for employees.",
      "file": "timesheets"
    }
  ]}

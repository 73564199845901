<template>
  <div class="shadow px-4 pb-4 rounded bg-white print:shadow-none print:p-0">
    <job-summary
        :data-loading="dataLoading"
        :summary="summary"
        class="-mx-4"
    />
    <ag-data-table
        :url="url"
        :compact="true"
        :columns="columns"
        :url-params="urlParams"
        :default-sort="defaultSort"
        :data-loading="dataLoading"
        :actionsColumnWidth="195"
        :add-text="$t('New line item')"
        :disable-col-flex="true"
        :import-url="`/job-costing/line-items/import?jobId=${$route.params.id}`"
        :resourceName="$globalResources.LineItems"
        :exclude-filters="['last_updated_at']"
        permission="line_items"
        dom-layout="autoHeight"
        hide-actions="delete,view,edit"
        actions="search,add,refresh,import,export,bulk-delete"
        :isRowSelectable="isRowSelectable"
        entity="line-items"
        class="print:mt-4"
        ref="table"
        @add="showAddLineItemDialog = true"
        @meta-fetch="metaFetch"
    >

      <template #additional-actions-before>
        <div class="min-w-[350px]">
          <base-select
              :label="$t('Line Item Types')"
              :placeholder="$t('Line Item Types')"
              :add-entity="false"
              :value="lineItemTypes"
              :options="lineItemTypeOptions"
              multiple
              @change="onChangeTypes"
          />
        </div>
      </template>

      <template #hours="{row}">
        <dl class="w-full px-2 leading-none flex flex-col space-y-1 py-1">
          <dt v-if="getLaborHours(row).total"
              class="flex justify-between space-x-1">
            <span>{{ $t(uniqueJobTypeAbbreviations.Labor) }}:</span>
            <span>{{ getLaborHours(row).label }}</span>
          </dt>
          <dt v-if="getEquipmentHours(row).total"
              class="flex justify-between space-x-1">
            <span>{{ $t(uniqueJobTypeAbbreviations.Equipment) }}:</span>
            <span>{{ getEquipmentHours(row).label }}</span>
          </dt>
        </dl>
      </template>

      <template #extra-actions="{row}">
        <table-view-button @click="onLineItemClick(row)" />
        <table-edit-button class="mt-1.5" @click="onLineItemEdit(row)"/>
        <base-button
            variant="gray-light"
            size="icon"
            class="table-action-button hidden md:block mt-1.5"
            :title="$t('View History')"
            @click="onViewHistory(row)"
        >
          <ActivityIcon class="w-4 h-4"/>
        </base-button>
        <base-button
            v-if="isCostLineItem(row)"
            variant="gray-light"
            size="icon"
            class="table-action-button hidden md:block mt-1.5"
            :title="$t('Update Progress')"
            @click="onEditBudgetProgress(row)"
        >
          <BarChartIcon class="w-4 h-4"/>
        </base-button>
        <table-delete-button
            v-if="$isAuthorized('authorizedToDelete', row)"
            @click="onDeleteLineItem(row)"
        />
      </template>

      <template #attributes.description="{row}">
        <router-link :to="getBudgetLink(row)">
          {{ row.attributes.description || '' }}
        </router-link>
      </template>

      <template #attributes.remaining="{row}">
        {{ $formatPrice(row.attributes.amount - row.attributes.amount_to_date) }}
      </template>

      <template #attributes.amount="{row}">
        <router-link :to="`/job-costing/${row.attributes.type}-line-items/${row.id}/view?fromJob=${jobId}`">
          {{ $formatPrice(row.attributes.amount) }}
        </router-link>
      </template>

      <template #attributes.amount_to_date="{row}">
        <router-link :to="`/job-costing/${row.attributes.type}-line-items/${row.id}/transactions?fromJob=${jobId}`">
          {{ $formatPrice(row.attributes.amount_to_date) }}
        </router-link>
      </template>

      <template #dropdown-actions>
        <TableActionItem>
          <BaseTooltip
            :content="$t('Copy Line Items From Another Job')"
            placement="top"
          >
            <div
              class="action-item-text text-gray-900"
              @click="showCopyLinesDialog = true"
            >
              <div class="p-2 bg-gray-100 mr-2 rounded-md">
                <CopyIcon class="w-4 h-4" />
              </div>
              <span>{{ $t('Copy Line Items') }}</span>
            </div>
          </BaseTooltip>
        </TableActionItem>
        <TableActionItem>
          <BaseTooltip
            :content="$t('Create equivalent Income/Cost Line Items')"
            placement="top"
          >
            <div
              class="action-item-text text-gray-900"
              @click="showMirrorLineItemsDialog = true"
            >
              <div class="p-2 bg-gray-100 mr-2 rounded-md">
                <LinkIcon class="w-4 h-4" />
              </div>
              <span>{{ $t('Mirror Line Items') }}</span>
            </div>
          </BaseTooltip>
        </TableActionItem>
      </template>
    </ag-data-table>

    <base-form-dialog
        v-if="showHistoryDialog"
        :title="$t('Job Line Items History')"
        :open.sync="showHistoryDialog"
        size="xl"
        @close="showHistoryDialog = false">
      <LineItemHistory :data="selectedBudget"/>
    </base-form-dialog>

    <base-form-dialog
        v-if="showEditLineItemDialog"
        :title="$t('Update Line Item Progress')"
        :open.sync="showEditLineItemDialog"
        size="lg"
        @close="showEditLineItemDialog = false"
    >
      <JobLineItemProgressUpdate
          :data="selectedBudget"
          :show-title="false"
          @save="onUpdateSaveBudget"
          @close="showEditLineItemDialog = false"
      />
    </base-form-dialog>
    <LineItemDialog
        v-if="showAddLineItemDialog"
        :open.sync="showAddLineItemDialog"
        :job="currentJob"
        @save="onUpdateSaveBudget"
        @close="showAddLineItemDialog = false"
    />
    <CopyLineItemsFromAnotherJobDialog
      v-if="showCopyLinesDialog"
      :open.sync="showCopyLinesDialog"
      :toJob="currentJob"
      @copied="onLineItemsCopied"
      @close="showCopyLinesDialog = false"
    />
    <MirrorLineItemsDialogDialog
      v-if="showMirrorLineItemsDialog"
      :open.sync="showMirrorLineItemsDialog"
      :job="currentJob"
      @mirrored="onLineItemsCopied"
      @close="showMirrorLineItemsDialog = false"
    />
    <DeleteResourcePreflightDialog
      v-if="showDeleteLineItemDialog"
      :open.sync="showDeleteLineItemDialog"
      :resource="lineItemToDelete"
      :resourceName="$globalResources.LineItems"
      @deleted="onLineItemDeleted"
      @close="showDeleteLineItemDialog = false"
    />
  </div>
</template>
<script>
  import { ActivityIcon, BarChartIcon } from 'vue-feather-icons'
  import { uniqueJobTypeAbbreviations } from '@/enum/enums'
  import { moduleAccountTypes } from '@/components/form/util'
  import JobSummary from '@/modules/job-costing/components/JobSummary'
  import TableViewButton from '@/components/table/actions/TableViewButton'
  import LineItemDialog from '@/modules/job-costing/components/line-items/LineItemDialog'
  import LineItemHistory from '@/modules/job-costing/components/line-items/LineItemHistory'
  import JobLineItemProgressUpdate from '@/modules/job-costing/components/line-items/JobLineItemProgressUpdate';
  import TableActionItem from '@/components/table/actions/TableActionItem.vue';
  import CopyLineItemsFromAnotherJobDialog from '@/modules/job-costing/components/CopyLineItemsFromAnotherJobDialog.vue';
  import MirrorLineItemsDialogDialog from '@/modules/job-costing/components/MirrorLineItemsDialog.vue';
  import { CopyIcon, LinkIcon } from 'vue-feather-icons';
  import {gridContext} from "@/components/ag-grid/gridContext";

  export default {
    name: 'JobLineItems',
    props: {
      canUpdatePath: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      ActivityIcon,
      BarChartIcon,
      JobSummary,
      LineItemHistory,
      LineItemDialog,
      TableViewButton,
      JobLineItemProgressUpdate,
      TableActionItem,
      CopyLineItemsFromAnotherJobDialog,
      MirrorLineItemsDialogDialog,
      CopyIcon,
      LinkIcon,
    },
    data() {
      return {
        uniqueJobTypeAbbreviations,
        defaultSort: 'phase_code,cost_code,change_order',
        showHistoryDialog: false,
        showAddLineItemDialog: false,
        showEditLineItemDialog: false,
        selectedBudget: {},
        summary: {},
        dataLoading: false,
        lineItemTypes: [moduleAccountTypes.Income, moduleAccountTypes.Cost],
        showCopyLinesDialog: false,
        showMirrorLineItemsDialog: false,
        lineItemTypeOptions: [
          {
            label: this.$t('Cost Line Items'),
            value: moduleAccountTypes.Cost,
          },
          {
            label: this.$t('Income Line Items'),
            value: moduleAccountTypes.Income,
          },
        ],
        columns: [
          {
            headerName: this.$t('Phase Code'),
            field: 'attributes.phase_code',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            headerName: this.$t('Cost Code'),
            field: 'attributes.cost_code',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            headerName: this.$t('Chg Order'),
            field: 'attributes.change_order',
            minWidth: 60,
            maxWidth: 70,
            align: 'center',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            minWidth: 180,
            maxWidth: 320,
          },
          {
            headerName: this.$t('Type'),
            field: 'attributes.type',
            align: 'center',
            minWidth: 40,
            maxWidth: 60,
            component: 'Status',
          },
          {
            headerName: this.$t('Budget'),
            field: 'attributes.amount',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Actual'),
            field: 'attributes.amount_to_date',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Remaining'),
            field: 'attributes.remaining',
            align: 'right',
            component: 'FormattedPrice',
            hide: true,
            minWidth: 100,
            maxWidth: 120,
          },
          {
            headerName: this.$t('% Est Compl'),
            field: 'attributes.completion',
            minWidth: 80,
            maxWidth: 100,
            align: 'right',
            component: 'FormattedPercent',
          },
          {
            headerName: this.$t('Est Final Cost'),
            field: 'attributes.est_final_cost',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Est. Variance'),
            field: 'attributes.est_variance_amount',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Hours (Budget / Actual)'),
            field: 'hours',
            align: 'center',
            minWidth: 120,
            autoHeight: true,
          },
        ],
        lineItemToDelete: null,
        showDeleteLineItemDialog: false,
      }
    },
    computed: {
      jobId() {
        return this.$route.params.id
      },
      currentJob() {
        return this.$store.state.jobCosting.currentJob
      },
      url() {
        return '/restify/line-items'
      },
      urlParams() {
        let type = this.lineItemTypes.join(',')

        // *In case all types are selected, send an empty array
        if (this.lineItemTypes.length === 2) {
          type = ''
        }

        return {
          job_id: this.jobId,
          related: 'budgets',
          type,
        }
      },
    },
    methods: {
      getLineItemBudget(lineItem, typeAbbr) {
        const { type } = lineItem.attributes
        const defaultSummary = {
          quantity: 0,
          quantity_to_date: 0,
        }
        const jobTypes = this.get(lineItem, `meta.summary.${type}.types`, [])
        const jobType = jobTypes.find((jobType) => jobType.abbr === typeAbbr)
        return jobType || defaultSummary
      },
      getLaborHours(lineItem) {
        const { quantity, quantity_to_date } = this.getLineItemBudget(lineItem, uniqueJobTypeAbbreviations.Labor)

        return {
          total: quantity + quantity_to_date,
          label: `${quantity} / ${quantity_to_date}`,
        }
      },
      getEquipmentHours(lineItem) {
        const { quantity, quantity_to_date } = this.getLineItemBudget(lineItem, uniqueJobTypeAbbreviations.Equipment)

        return {
          total: quantity + quantity_to_date,
          label: `${quantity} / ${quantity_to_date}`,
        }
      },
      async onLineItemClick(lineItem) {
        const type = lineItem?.attributes.type
        await this.$router.push(`/job-costing/${type}-line-items/${lineItem.id}/view`)
      },
      async onLineItemEdit(lineItem) {
        const type = lineItem?.attributes.type
        await this.$router.push(`/job-costing/${type}-line-items/${lineItem.id}/edit`)
      },
      isCostLineItem(lineItem) {
        const type = lineItem?.attributes.type
        return type === moduleAccountTypes.Cost
      },
      onViewHistory(budget) {
        this.selectedBudget = budget
        this.showHistoryDialog = true
      },
      onEditBudgetProgress(budget) {
        this.selectedBudget = budget
        this.showEditLineItemDialog = true
      },
      onUpdateSaveBudget() {
        this.showEditLineItemDialog = this.showAddLineItemDialog = false
        this.refreshTable()
      },
      metaFetch(meta) {
        this.summary = meta?.summary || {}
      },
      onChangeTypes(value) {
        this.lineItemTypes = value ? value.sort() : []
        gridContext.lastRequestParams.costType = value.join(',')
      },
      refreshTable() {
        this.$refs.table.refresh({}, true)
      },
      getTotalActualAmount(row) {
        const { amount_to_date, type } = row.attributes
        if (type === moduleAccountTypes.Cost) {
          return this.$formatPrice(amount_to_date)
        }

        const amount = Math.abs(amount_to_date)
        return this.$formatPrice(amount)
      },
      getBudgetLink(row) {
        const { id, type } = row.attributes
        return `/job-costing/${type}-line-items/${id}/view?fromJob=${this.jobId}`
      },
      onDeleteLineItem(lineItem) {
        this.lineItemToDelete = lineItem
        this.showDeleteLineItemDialog = true
      },
      onLineItemDeleted(lineItem) {
        this.showDeleteLineItemDialog = false
        this.refreshTable()
      },
      isRowSelectable(params) {
        return params.data?.meta?.authorizedToDelete
      },
      onLineItemsCopied() {
        this.refreshTable()
      },
    },
  }
</script>
